import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface HeadingProps {
  size?: number | string;
  weight?: string;
  text?: string;
  alternating?: boolean;
  alternateColor?: string;
  className?: string;
  children?: ReactNode;
  [key: string]: any;
}

const Heading: FC<HeadingProps> = ({
  size = 5,
  weight = 'font-bold',
  text = '',
  alternating = false,
  alternateColor = '#476E30',
  className,
  children,
  ...props
}) => {
  const Tag =
    `h${typeof size === 'number' ? size : 2}` as keyof JSX.IntrinsicElements;

  const styles: Record<number | string, string> = {
    1: `lg:text-6xl text-4xl`,
    2: `lg:text-5xl text-3xl`,
    3: `lg:text-4xl text-2xl`,
    4: `text-xl`,
    5: `lg:text-xl text-base`,
    6: `text-base`,
    '40px': `text-[40px] md:text-[60px] lg:text-[80px] leading-[1.4]`,
    custom: `text-base-[72px]`,
  };

  // For 40px size, we'll use a div with inline styles to ensure proper line height
  if (size === '40px') {
    return (
      <div
        className={twMerge(
          `${weight} ${alternating && 'heading-alternate'}`,
          className
        )}
        style={{
          lineHeight: '1.4',
          marginBottom: '1rem',
        }}
        {...props}
      >
        {children || text || ''}
      </div>
    );
  }

  return (
    <Tag
      className={twMerge(
        `${weight} ${styles[size]} ${alternating && 'heading-alternate'}`,
        className
      )}
      {...props}
    >
      {children || text || ''}
    </Tag>
  );
};

export default Heading;
